import axios from 'axios';

const email_path = "v1/trainee/auth/password/email";
const reset_path = "v1/trainee/auth/password/reset";

function requestEmail(data)
{
    return new Promise((resolve, reject) =>{
        axios.post(email_path, data)
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function resetPassword(data)
{
    return new Promise((resolve, reject) =>{
        axios.post(reset_path, data)
            .then(() => {
                resolve();
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

export { requestEmail, resetPassword };