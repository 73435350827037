import axios from 'axios';
import store from '@/store';
import CookieHelper from '@/helpers/CookieHelper';

const login_path = "v1/trainee/auth/login";
const logout_path = "v1/trainee/auth/logout";

function login(formData, remember = false)
{
    return new Promise((resolve, reject) =>{
        axios.post(login_path, formData)
            .then((response)=>{

                if (remember){
                    CookieHelper.setToken(response.data.token, {expires: 30});
                } else {
                    CookieHelper.setToken(response.data.token);
                }

                // add report_count and video_count to user object, before saving.
                const report_count = response.data.report_count;
                const video_count = response.data.video_count;
                const user = {...response.data.trainee, report_count, video_count};
                
                // console.log('ingelogd:', user);
                store.commit('setUser', user);
                store.commit('setToken', response.data.token);

                resolve(response);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function logout()
{
    return new Promise((resolve, reject) =>{
        axios.post(logout_path, null)
            .then(() => {
                afterLogout();
                resolve();
            })
            .catch((err) => {
                if(err.response?.status === 401){
                    // User is already unauthenticated, delete local tokens.
                    afterLogout();
                    return resolve();
                }

                return reject(err.response?.data ?? err);
            });
    });
}

function afterLogout()
{
    store.commit('setToken', null);
    store.commit('setUser', null);

    CookieHelper.deleteToken()
}

export { login, logout };