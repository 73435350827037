<template>
    <div>
        <label v-if="label" :for="label" class="block text-xs sm:text-sm font-medium leading-5" :class="altStyling ? 'text-gray-100':'text-gray-100'">{{ label }}</label>
        <div class="mt-2 relative rounded-md shadow-sm border-gray-200 border">
            <input ref="input" :id="label" :type="type" :value="value" @input="updateValue()" @change="clearErrors()" :class="inputClasses + (altStyling ? ' bg-transparent':'')" :placeholder="placeholder" :aria-invalid="hasErrors ? 'true' : 'false'" :aria-describedby="label" :autofocus="autofocus !== undefined" :autocapitalize="autocapitalize">
            <div v-if="hasErrors" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 font-medium text-sm text-red-600">{{ error }}</p>
        </template>
    </div>
</template>

<script>
export default {
    name: "TextInput",
    props: ['value', 'errors', 'label', 'password', 'placeholder', 'autofocus', 'autocapitalize', 'altStyling'],
    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
        inputClasses: function () {
            if (this.hasErrors){
                return "form-input block w-full bg-gray-900 pr-10 text-white border-red-300 placeholder-red-500 focus:border-red-300 focus:shadow-outline-red focus:bg-gray-950 text-xs sm:text-sm sm:leading-5 relative rounded-md shadow-sm p-3 sm:p-4";
            } else {
                return "form-input block w-full bg-transparent text-xs sm:text-sm sm:leading-5 relative rounded-md shadow-sm p-3 sm:p-4 text-white focus:bg-gray-950 transition duration-200";
            }
        },
        type: function () {
            return this.password !== undefined ? 'password' : 'text';
        },
    },
   
    methods:{
        updateValue(){
            this.$emit('update:value', this.$refs.input.value);
        },
        clearErrors(){
            this.$emit('update:errors', []);
        }
    }
}
</script>

<style scoped>

</style>
