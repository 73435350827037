<template>
    <Guest :title="t('page_title')">
        <form v-on:submit.prevent="handleSubmit" class="h-full">
            <PageWithButtonBelow>
                <template v-slot:default>
                    <p class="pb-4 text-white">{{ t('explanation') }}</p>
                    <TextInput v-model:value="form.email" v-model:errors="errors.email" :label="t('email')" :placeholder="t('email')" autofocus />
                </template>

                <template v-slot:button>
                    <div class="mt-12">
                        <span class="block w-full rounded-md shadow-sm">
                            <button type="submit" class="button w-full">
                                {{ t('send') }}
                            </button>
                        </span>
                    </div>
                    <div class="mt-6">
                        <router-link :to="{ name: 'login'}">
                            <span class="block w-full rounded-md shadow-sm">
                                <button type="submit" class="button-transparent w-full">
                                    {{ t('back') }}
                                </button>
                            </span>
                        </router-link>
                    </div>
                </template>
            </PageWithButtonBelow>
        </form>
    </Guest>
</template>

<script>
// import axios from 'axios';
import Guest from '@/wrappers/Guest';
import TextInput from "@/components/forms/TextInput";
import { requestEmail } from "@/helpers/api/PasswordResetHelper";
import messages from "@/helpers/storage/MessageHelper";
import { setLoading } from "@/helpers/storage/loadingSpinnerHelper";
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import useI18n from "@/modules/globalI18n";

export default {
    name: "PasswordForgot",
    components: { 
        Guest, 
        TextInput,
        PageWithButtonBelow,
    },
    setup() {
        return useI18n({
            viewPrefix: "PasswordForgot"
        });
    },
    data() {
        return {
            form: {
                email: '',
            },
            errors: {
                email: [],
            },
        }
    },
    methods: {
        handleSubmit(){
            setLoading(true);

            requestEmail(this.form)
                .then(() => {
                    messages.success(this.t('messages.email_send'));
                })
                .catch(err => {
                    messages.error(this.t('messages.email_not_send'));
                    this.errors = err.errors;
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
}
</script>