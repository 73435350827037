<template>
    <div class="h-full" :class="background ? 'background-image': 'bg-black'">
        <video v-if="background" playsinline autoplay muted loop poster="~@/assets/images/background/background.jpg" class="background-video">
            <source src="~@/assets/images/background/background.mp4" width="100%" height="100%" autoplay type="video/mp4">
        </video>
        <div class="content h-full max-w-lg mx-auto p-10">
            <div v-if="isLoggedIn()" @click="logout" class="float-right w-8 cursor-pointer">
                <svg class="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
            </div>
            <div class="h-full body-content">
                <div v-if="logo" class="mb-10">
                    <img class="h-8 w-auto" src="@/assets/images/logo/logo-white.svg" alt="Scorelit logo">
                </div>
                <h1 v-if="title" class="text-white font-semibold text-2xl pb-4 whitespace-pre-line" v-bind:class="text_center ? 'text-center': ''" v-text="title"></h1>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { logout } from "@/helpers/api/AuthHelper";
import { isLoggedIn } from "@/helpers/storage/AuthHelper";

export default {
    name: "Guest",
    props: {background: Boolean, logo: Boolean, title: String, text_center: Boolean},
    methods: {
        logout(){
            logout()
                .then(()=>{
                    this.$router.push("/login");
                })
                .catch((data)=>{
                    console.error('Logout unsuccessful: ', data)
                });
        },
        isLoggedIn,
    },
}
</script>

<style scoped>
    .background-image {
        /* https://www.w3schools.com/html/html_images_background.asp */
        background: #000000;
        /* ToDo: change background */
        background-image: url('~@/assets/images/background/background.jpg'); /* fallback */
        background-image: linear-gradient(#000000, #0000), url('~@/assets/images/background/background.jpg');
        background-repeat: no-repeat;
        background-position: center; 
        background-attachment: fixed;
        background-size: cover;
    }
    .background-video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .content {
        position: relative;
        z-index: 10;
    }
    .body-content {
        padding-top: 4vh;
    }

    .min-width-40 {
        min-width: 40px;
    }
</style>